.service {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.arch {
    height: 70vh;
}

.arch img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    border-top-left-radius: 70vh;
    border-top-right-radius: 70vh;
}

.service h3 {
    text-align: center;
    color:#6f4335;
}

.service p {
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: #515148;
}

@media (min-width: 480px) { 
    .arch, .arch img {
        height: 40vh;
        width: 20vw;
    }

    .service {
        width: 20vw;
    }
}