* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'ss-pro';
}

@font-face {
  font-family: 'sprat';
  src: local('sprat'), url(./assets/fonts/Sprat-Light.otf) format('opentype');
}

@font-face {
  font-family: 'ss-pro';
  src: local('ss-pro'), url(./assets/fonts/SweetSansProRegular.otf) format('opentype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

button:hover {
  cursor: pointer;
}

#whatsapp {
  position: fixed;
  height: 36px;
  width: 36px;
  right: 24px;
  bottom: 24px;
  z-index: 10;
}

#whatsapp a {
  position: relative;
}

@media (min-width: 480px) { 
  #whatsapp {
    display: none;
  }
}