.artist-blob {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.artist-blob img {
    border-radius: 50%;
    height: 100px;
    object-fit:cover;
    clip-path: circle();
}

.artist-blob h4 {
    font-weight: 200;
    text-align: center;
}

.artist-title {
    padding-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid #515148;
}

.artist-title h2 {
    color: #515148;
    font-weight: 100;
    font-size: 18px;
}

.artists-singer {
    padding-top: 48px;
}

.artists-singer-content {
    display: none;
}

@media (min-width: 480px) {
    .singer-swiper {
        display: none;
    }

    .artists-singer {
        width: 50%;
    }

    .artists-singer-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 12px;
        padding-top: 48px;
    }

    .artist-title h2 {
        font-size: 28px;
    }

    .artist-container {
        display: flex;
    }
}
