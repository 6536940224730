@media (min-width: 480px) {

    .home-img-con,
    .home-about,
    .home-services,
    .home-artists,
    .home-circle {
        padding-top: 0;
        padding-left: 48px;
        padding-right: 48px;
    }

    .home-img-con button {
        display: none;
    }

    .home-circle {
        display: contents;
    }

    .home-about {
        display: contents;
    }

    #home-circle-grid {
        margin-left: 48px;
        width: calc(100% - 96px);
        height: calc((100vw - 48px) / 2);
        background-color: #e6e0d3;
        opacity: 0.5;
        position: absolute;
        border-bottom-left-radius: 50vw;
        border-bottom-right-radius: 50vw;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr 2fr;
        grid-template-areas: ". welcome title elsa ."
            "text text text text text";
        column-gap: 24px;
        align-items: center;
    }

    .home-circle h4:nth-child(1) {
        grid-area: welcome;
    }

    .home-circle h4:nth-child(2) {
        grid-area: elsa;
    }

    .home-about h3 {
        grid-area: title;
        color: inherit;
        font-size: 42px;
    }

    .home-about p {
        grid-area: text;
        justify-self: center;
        align-self: flex-start;
        width: 70%;
        color: inherit;
    }

    .home-services {
        z-index: 2;
        position: relative;
        padding-top: calc((100vw - 48px) / 2.7);
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .home-services button {
        width: 30%;
    }

    .home-artists {
        padding-top: 128px;
    }

    .arch-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'leftarch rightarch';
    }

    .arch-sideway {
        display: contents;
    }

    .left-arch {
        grid-area: leftarch;
        height: 50vw;
    }
    
    .right-arch {
        width: 50vw;
        height: 50vw;
        grid-area: rightarch;
    } 

    .home-promise {
        padding: 0;
        grid-area: leftarch;
        align-self: center;
        padding-left: 48px;
        width: 40vw;
        z-index: 2;
    }

    .home-promise h2 {
        font-size: 42px;
        width: 25vw;
    }
}