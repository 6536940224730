.home {
    padding-top: 24px;
}

.home-img-con,
.home-about,
.home-services,
.home-artists,
.home-promise {
    padding: 0 24px 0 24px;
}

.home-circle {
    margin-left: 24px;
}

.home-img-con img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.home-img-con button {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);

    font-size: 18px;
    background-color: #fff;
    padding: 12px 24px 12px 24px;
    border: none;
    color: #331f18;
}

.home-circle {
    width: calc(100% - 48px);
    height: calc((100vw - 48px) / 2);
    background-color: #e6e0d3;
    opacity: 0.5;
    position: absolute;
    border-bottom-left-radius: 50vw;
    border-bottom-right-radius: 50vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 24px;
}

.home-circle h4 {
    text-align: center;
}

.home-about {
    padding-top: calc(((100vw - 48px) / 2) + 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.home-about h3 {
    text-align: center;
    font-family: 'sprat';
    font-size: 28px;
    color: #d1c6b6;
}

.home-about p {
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: #515148;
}

.home-services button {
    font-size: 18px;
    width: 70%;
    background-color: #6f4335;
    color: #e6e0d3;
    padding: 12px 24px 12px 24px;
    border: none;
}

.home-services {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
}

.home-artists {
    padding-top: 48px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

}

.artists-header {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #000;
    color: #515148;
}

.home-artists img {
    border-radius: 50%;
    height: 120px;
    clip-path: circle();
}

.arch-sideway {
    padding-top: 48px;
    display: flex;
    justify-content: flex-end;
}

.left-arch {
    height: 60vh;
    width: 50vw;
    border: 1px solid #6f4335;
    border-top-right-radius: 60vw;
    border-bottom-right-radius: 60vw;

    position: absolute;
    left: 0;
}

.right-arch {
    height: 60vh;
    width: 80vw;
    object-fit: cover;
    border-top-left-radius: 60vw;
    border-bottom-left-radius: 60vw;
}

.home-promise {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.home-promise h4 {
    color: #515148;
}

.home-promise h2 {
    width: 50vw;
    font-family: 'sprat';
    font-size: 32px;
    color: #d1c6b6;
}

.home-promise p {
    font-size: 16px;
    line-height: 32px;
    color: #515148;
}

.home-promise button {
    color: #515148;
    font-size: 18px;
    width: 70%;
    padding: 12px 24px 12px 24px;
    border: 1px solid #887769;
    background-color: #fff;
}

.home-promise button:hover {
    cursor: pointer;
}