.blog-post-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 48px;
}

.blog-post-list a {
    text-decoration-color: #ccc;
}

@media (min-width: 480px) { 
    .blog-post-list {
        width: 40%;
        padding-left: 48px;
        padding-right: 48px;
    }
}