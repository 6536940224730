.nav-main {
    padding: 24px 24px 0 24px;
}

.nav-logo-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-main-active {
    padding-bottom: 48px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #e6e0d3;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.nav-main a {
    width: 70%;
}

.line1,
.line2 {
    width: 43px;
    height: 1px;
    background-color: #6f4335;
    transition: all 300ms ease-in-out;
}

.burger-active .line1 {
    transform: rotate(-45deg) translate(-3px, 6px);
}

.burger-active .line2 {
    transform: rotate(45deg) translate(-3px, -6px);
}

.burger {
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 2;
}

.burger-active {
    background-color: #e6e0d3;
}

.nav-inactive {
    display: none;
}

.active-links-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.active-links-nav li {
    list-style-type: none;
}

.nav-main a {
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 32px;
    color: #331f18;
}

a.active {
    text-decoration: underline;
}

.social-nav-active li:nth-child(1),
.social-nav-active li:nth-child(2), 
.social-nav-active li:nth-child(3) {
    display: none;
}

@media (min-width: 480px) {
    .burger {
        display: none;
    }

    .nav-main {
        display: grid;
        column-gap: 12px;
        align-items: center;
        /* centers items vertically */
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "links logo";

        padding: 48px 48px 0 48px;
    }

    .nav-logo-box {
        justify-content: center;
        margin-bottom: -80px;
        z-index: 2;
        position: relative;
        width: fit-content;

        grid-area: logo;
    }

    .nav-main a {
        width: fit-content;
        font-size: 16px;
    }

    .nav-inactive {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
    }

    .main-nav {
        justify-content: flex-start;
    }

    .nav-inactive li {
        list-style-type: none;
    }

    .nav-main li:nth-child(5) {
        display: none;
    }

    .nav-main li:nth-child(6) {
        display: none;
    }

    .nav-main li:nth-child(7) {
        display: none;
    }

}