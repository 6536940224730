.service-page {
    padding-top: 24px;
}

.service-pg-descr {
    margin-top: 24px;
    margin-right: 24px;
    padding-top: 24px;
    padding-left: 12px;
    border-top: 1px solid #331f18;
}

.service-pg-descr h1 {
    font-family: 'sprat';
    font-size: 28px;
    line-height: 32px;
    letter-spacing: normal;
    font-weight: 600;
    color: #d1c6b6;
}

.service-pg-descr p {
    text-align: justify;
    width: 90%;
    padding-top: 24px;
    font-family: 'ss-pro';
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    color: #515148;
}

@media (min-width: 480px) {
    .service-pg-descr {
        margin-right: 0;
        margin-top: 48px;
        padding-top: 48px;
        padding-left: 182px;
        padding-right: 182px;

    }

    .service-pg-descr p {
        width: 100%;
        height: 25%;
    }

    .service-pg-descr h1 {
        text-align: center;
    }

    .service-pg-descr div {
        display: flex;
        justify-content: space-between;
        gap: 56px;
    }
}

.service-pg-offer-heading {
    margin-top: 48px;
    padding-left: 24px;
    padding-right: 24px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'left mid right';
    align-items: center;
    color: #331f18;
}

.service-pg-offer-heading h3 {
    font-family: 'ss-pro';
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.6px;
}

.service-pg-offer-heading h3:nth-child(1) {
    grid-area: left;
}

.service-pg-offer-heading h3:nth-child(2) {
    grid-area: right;
    justify-self: flex-end;
}

.service-pg-offer-heading h2 {
    font-family: 'sprat';
    grid-area: mid;
    text-align: center;
    font-size: 32px;
    line-height: 36px;
    font-weight: 300;
}

@media (min-width: 480px) {

    .service-pg-offer-heading {
        grid-template-columns: 1fr 200px 1fr;
        column-gap: 24px;
        padding-top: 24px;
    }

    .service-pg-offer-heading h2 {
        font-size: 56px;
        text-align: center;
        line-height: 62px;
    }

    .service-pg-offer-heading h3:nth-child(1) {
        justify-self: flex-end;
    }

    .service-pg-offer-heading h3:nth-child(2) {
        justify-self: flex-start;
    }
}

.service-pg-list {
    padding: 0 24px 0 24px;
}

.service-pg-list-item {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.service-pg-list-item ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.service-pg-list-item li {
    list-style: none;
    display: grid;
    grid-template-columns: 36px 1fr;
    grid-template-areas: 'icon text';
    column-gap: 12px;
}

.service-pg-list-item svg {
    grid-area: icon;
}

.service-icon-txt {
    grid-area: text;
}

.service-pg-list-item h4 {
    font-family: 'sprat';
    font-size: 18px;
    line-height: 36px;
    color: #331f18;
}

.service-pg-list-item p {
    font-family: 'ss-pro';
    font-size: 16px;
    color: #515148;
    text-align: justify;
}

.service-img-desk {
    display: none;
}

@media (min-width: 480px) {

    .service-pg-list {
        padding: 0;
    }

    .service-pg-list-item .service {
        display: none;
    }

    .service-img-desk {
        display: flex;
    }

    .service-img-desk div {
        padding-right: 24px;
        padding-left: 10vw;
        width: 50%;
        height: 70vh;
        border-bottom-left-radius: 700px;
        border-top-left-radius: 700px;
        border: 1px solid #515148;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        justify-content: center;
    }

    .service-pg-list-item ul {
        padding: 24px 10vw 56px 10vw;
        flex-direction: row;
    }

    .service-img-desk p {
        text-align: left;
        width: 90%;
        font-size: 18px;
    }

    
    .service-img-desk h2 {
        text-align: left;
        font-size: 42px;
        font-family: 'sprat';
        color: #6f4335;
    }

    .service-img-desk img {
        width: 50%;
        height: 70vh;
        object-fit: cover;
        border-bottom-right-radius: 70vh;
        border-top-right-radius: 70vh;
    }

    .service-pg-list-item h4 {
        font-size: 24px;
    }
}