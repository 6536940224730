.blog-card {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.blog-card img {
    height: 150px;
    width: 100%;
    object-fit: cover;
}

.blog-card h3 {
    text-align: left;
    font-weight: 100;
    font-size: 18px;
    color: #515148;
}

@media (min-width: 480px) { 
    .blog-card img {
        height: 200px;
    }
}