.about-title {
    margin-top: 48px;
    padding-top: 24px;
    margin-left: 48px;
    padding-right: 24px;
    border-top: 1px solid #515148;
    text-align: right;
}

.about-title h3 {
    font-size: 36px;
    font-family: 'sprat';
    font-weight: 100;
    color: #515148;
}

.about-info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-end;
    margin-left: 48px;
    padding-right: 12px;
    padding-top: 24px;
    text-align: justify;
}

.about-info p {
    color: #515148;
    font-size: 16px;
    line-height: 32px;
}

@media (min-width: 480px) { 
    .about-title {
        margin-left: 0;
        padding-top: 48px;
        padding-left: 24px;
        text-align: center;
    }

    .about-info {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        gap: 0;
        padding-top: 48px;
    }

    .about-info p {
        width: 40%;
    }
}