.header-shared {
    height: 35vh;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 18px;
    grid-template-areas: 'header';
}

.header-shared img {
    grid-area: header;
    width: 100%;
    height: 35vh;
    object-fit: cover;
    border-bottom-left-radius: 30vh;
    border-bottom-right-radius: 30vh;
}

.header-shared div {
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

.header-shared h4 {
    font-family: 'ss-pro';
    font-size: 16px;
    color: #fff;
}

.header-shared h3 {
    color: #fff;
    font-size: 36px;
    font-family: 'sprat';
    font-weight: 100;
}

@media (min-width: 480px) { 
    .header-shared {
        padding: 0 24px 0 24px;
    height: 40vh;
    }

    .header-shared img {
        height: 40vh;
    }

    .header-shared h3 {
        font-size: 48px;
    }
}