.privacy-policy {
    margin-top: 48px;
    padding: 0 10vw 0 10vw;
}

.privacy-policy h2 {
    text-align: center;
}

.privacy-policy h3 {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 12px;
}

.privacy-policy p,
.privacy-policy li,
.privacy-policy h4,
.privacy-policy h2 {
    padding-bottom: 12px;
}

.privacy-policy p,
.privacy-policy li {
    color: #515148;
}