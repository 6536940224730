.blog-post {
    padding: 24px;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

.blog-post h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    color: #515148;
}

.blog-post h1 {
    font-weight: 100;
    font-family: 'Sprat';
    color: #515148;
    text-align: center;
    font-size: 36px;
}

.blog-post p {
    color: #515148;
    line-height: 32px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
}

.blog-post-img-container p {
    padding-bottom: 24px;
}

.blog-post img {
    width: 100%;
}

.blog-post .divider {
    border-top: 1px solid #515148;
    height: 1px;
    width: 100%;
}

.blog-post h2 {
    font-weight: 100;
    font-family: 'Sprat';
    color: #515148;
    text-align: left;
    font-size: 28px;
}

.blog-post li {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding-bottom: 18px;
}

.blog-post h5 {
    font-size: 18px;
}

@media (min-width: 480px) { 
    .blog-post {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 162px;
        gap: 48px;
    }

    .blog-post img {
        height: 400px;
        width: auto;
    }

    .blog-post h1 {
        font-size: 60px;
    }

    .blog-post h2 {
        font-size: 54px;
        text-align: center;
    }

    .blog-post p {
        font-size: 22px;
    }

    .blog-post-img-container {
        display: flex;
        justify-content: center;
        gap: 24px;
    }

    .blog-post-img-container p {
        width: 40%;
        height: 100%;
        text-align: justify;
    }
}