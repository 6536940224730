.contact-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.contact-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
    color: #515148;
}

.contact-header h3 {
    font-size: 28px;
}

.contact-header h5 {
    font-size: 16px
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.contact-form input, .contact-form textarea, .contact-form button {
    padding: 12px;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: 1px solid #6f4335;
}

.contact-form button {
    border: none;
    background-color: #6f4335;
    color: #e6e0d3;
}

.contact-form button:active {
    background-color: #e6e0d3;
}

.contact-form button:disabled {
    background-color: #6f4335;
    opacity: 50%;
    cursor:default;
}

.contact-loading {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: rgba(204, 204, 204, 0.5);
    position: fixed;

    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 480px) { 

    .contact-content {
        padding: 52px;
        flex-direction: row;
        align-items: center;
    }

    .contact-header {
        width: 50%;
    }

    .contact-header h3 {
        font-size: 48px;
    }
    
    .contact-header h5 {
        font-size: 24px
    }

    .contact-form {
        width: 50%;
    }
}