.comingsoon {
    margin-top: 48px;
    border-top: 1px solid #331f18;
}

.comingsoon h2 {
    padding-top: 24px;
    text-align: center;
    font-family: 'sprat';
}

.comingsoon p {
    text-align: center;
    padding-top: 24px;
}