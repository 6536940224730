footer {
    margin-top: 48px;
    padding: 24px;
    padding-bottom: 48px;
    background-color: #6f4335;

    display: flex;
    flex-direction: column;
}

footer h3 {
    padding-top: 24px;
    font-size: 24px;
    line-height: 32px;
    color: #e6e0d3;
    font-family: 'sprat';
}

footer a {
    padding-top: 8px;
    color: #e6e0d3;
    text-decoration: none;
    font-size: 16px;
    line-height: 32px;
}

footer img {
    border-top: 1px solid #e6e0d3;
    margin-top: 24px;
   padding: 24px 48px 0 48px;
}

footer p {
    text-align: center;
    color: #e6e0d3;
    padding-top: 12px;
}

@media (min-width: 480px) { 

    footer {
        align-items: center;
    }

    footer img {
        width: 25vw;
    }
}